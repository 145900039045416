import { SESSION_STORAGE_KEYS } from '~/core';

import { useBookingStore } from '~~/domains/booking';
import { useIdentificationPlainTextQuery } from '~~/domains/graphql';
import { useUserStore } from '~~/domains/user';

export function useHasIdentificationCookies() {
  const hw2h = useCookie('_Hw2h_');
  const jsessionId = useCookie('JSESSIONID');
  const org = useCookie('org');
  const identification = useCookie('identification_com.allinclusive');
  const idToken = useCookie('identification-token');

  return computed(() =>
    Boolean(
      hw2h.value &&
        jsessionId.value &&
        org.value &&
        identification.value &&
        idToken.value,
    ),
  );
}

export function useIdentificationPlaintext() {
  const userStore = useUserStore();
  const bookingStore = useBookingStore();

  const promotionalInformations = computed(() => ({
    cardNumber: userStore.loyaltyCardNumber,
    ...bookingStore.promotionalInformation,
  }));

  const isIdentified = useHasIdentificationCookies();
  const enabled = computed(() =>
    Boolean(!isIdentified.value && process.client),
  );

  const { onResult, onError } = useIdentificationPlainTextQuery(
    promotionalInformations,
    {
      fetchPolicy: 'no-cache',
      enabled,
    },
  );

  onResult(result => {
    if (result.loading) return;

    Object.values(SESSION_STORAGE_KEYS).forEach(value => {
      if (value.includes('booking::')) {
        window.sessionStorage.removeItem(value);
      }
    });
  });

  onError(error => {
    // eslint-disable-next-line no-console
    console.error(error);
  });
}
