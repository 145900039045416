import { ApolloLink } from '@apollo/client/core';

import { parseRawCookie } from '~/helpers';

export const headerLink = new ApolloLink((operation, forward) => {
  const cookie = parseRawCookie(null);

  if (cookie) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        cookie,
      },
    }));
  }

  return forward(operation).map(data => {
    const { response } = operation.getContext();
    const setCookie = response.headers.get('x-set-cookie');

    if (typeof setCookie !== 'string') return data;

    try {
      const cookies = JSON.parse(setCookie) as string[];

      if (!Array.isArray(cookies)) return data;

      for (const cookie of cookies) {
        document.cookie = cookie
          .split('; ')
          .filter(cookiePart => !cookiePart.toLowerCase().startsWith('domain='))
          .join('; ');
      }
    } catch {
      return data;
    }

    return data;
  });
});
